@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7 !important;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

::-webkit-scrollbar-thumb {
  background-color: #ed6d5c !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-switch-checked {
  background-color: #27ae60;
}

/* markdown.css */

/* Base typography for the markdown container */
.markdown {
  line-height: 1.3;
  color: #374151; /* Tailwind gray-700 */
}

/* Headings */
.markdown h1 {
  font-size: 2.25rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.markdown h2 {
  font-size: 1.875rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.markdown h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}

.markdown h4,
.markdown h5,
.markdown h6 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

/* Paragraphs */
.markdown p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Links */
.markdown a {
  color: #2563eb; /* Tailwind blue-600 */
  text-decoration: underline;
}

.markdown a:hover {
  color: #1d4ed8; /* Tailwind blue-700 */
}

/* Lists */
.markdown ul,
.markdown ol {
  padding-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown ul {
  list-style-type: disc;
}

.markdown ol {
  list-style-type: decimal;
}

.markdown li {
  margin-bottom: 0.5rem;
}

/* Blockquotes */
.markdown blockquote {
  border-left: 4px solid #d1d5db; /* Tailwind gray-300 */
  padding-left: 1rem;
  color: #6b7280; /* Tailwind gray-500 */
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Code blocks */
.markdown pre {
  background-color: #f3f4f6; /* Tailwind gray-100 */
  padding: 1rem;
  overflow-x: auto;
  border-radius: 0.375rem; /* Tailwind rounded-md */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Inline code */
.markdown code {
  background-color: #f3f4f6;
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 0.95em;
}

/* Horizontal Rule */
.markdown hr {
  border: none;
  border-top: 1px solid #d1d5db;
  margin: 2rem 0;
}

/* Tables */
.markdown table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown th,
.markdown td {
  border: 1px solid #d1d5db;
  padding: 0.75rem;
}

.markdown th {
  background-color: #f3f4f6;
  font-weight: 600;
}

/* Images */
.markdown img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
}
